import React, { useState, useEffect } from "react";
import Loadable from "@loadable/component"
// import Footer from "../components/Footer/Footer"
import Header from "../components/Header/Header"
import HomeBannerSearch from "../components/HomeBannerSearch/HomeBannerSearch"
import { useMedia } from "react-use";
import { isIOS } from "react-device-detect";

const TileBlockModule = Loadable(() => import("../components/TileBlock/TileBlockModule"));
// import TileBlockModule from "../components/TileBlock/TileBlockModule"
// import BeInspiredSlider from "../components/BeInspiredSlider/BeInspiredSlider"
const BeInspiredSlider = Loadable(() => import("../components/BeInspiredSlider/BeInspiredSlider"));
const OurPeopleSlider = Loadable(() => import("../components/OurPeopleSlider/OurPeopleSlider"));
const TestimonialSlider = Loadable(() => import("../components/TestimonialSlider/TestimonialSlider"));
// import FeaturedPropertiesSlider from "../components/FeaturedPropertiesSlider/FeaturedPropertiesSlider"
const FeaturedPropertiesSlider = Loadable(() => import("../components/FeaturedPropertiesSlider/FeaturedPropertiesSlider"));
const Footer = Loadable(() => import("../components/Footer/Footer"));

const HomeComponents = props => {
  const GQLPage = props.GQLPage
  const GQLModules = props.GQLModules


  const isMobile = useMedia("(max-width: 768px)", false)
  const isTablet = useMedia("(min-width: 1024px)")

  const [renderComponent, setRenderComponent] = useState(false);
  useEffect(() => {
    if (isIOS) {
      setRenderComponent(true)
    } else {
      window.addEventListener("mousemove", () => {
        if (renderComponent === false) {
          setRenderComponent(true)
        }
      })
      window.addEventListener("touchmove", () => {
        if (renderComponent === false) {
          setRenderComponent(true)
        }
      })
      window.addEventListener("touchstart", () => {
        if (renderComponent === false) {
          setRenderComponent(true)
        }
      })
      window.addEventListener("keypress", () => {
        if (renderComponent === false) {
          setRenderComponent(true)
        }
      })
    }
  }, [])

  return (
    <div className={`${GQLPage.page_class ? GQLPage.page_class : ""}`}>
      <Header layout={GQLPage.layout} />
      {GQLPage.alias === "home" &&
        <HomeBannerSearch extraClass="position-absolute" />}


      {GQLModules.map((module, i) => {
        return (
          <>
            {module.__typename ===
              "GLSTRAPI_ComponentComponentsTextBlockWithImage" &&
              (<>
                {renderComponent || !isMobile ?
                  <TileBlockModule module={module} /> : ""
                }
              </>)}

            {module.__typename ===
              "GLSTRAPI_ComponentComponentsSelectGlobalModules" &&
              module.Select_Module === "Be_Inspired_Section" && (
                <>
                  {renderComponent || !isMobile ?
                    <BeInspiredSlider module={module} /> : ""
                  }
                </>
              )}

            {module.__typename ===
              "GLSTRAPI_ComponentComponentsShowFeaturedProperty" && (
                <>
                  {renderComponent || !isMobile ?
                    <FeaturedPropertiesSlider module={module.show} /> : ""
                  }
                </>
              )}
            {module.__typename ===
              "GLSTRAPI_ComponentComponentsSelectGlobalModules" &&
              module.Select_Module === "Team_Slider_Module" && (
                <>
                  {renderComponent || !isMobile ?
                    <OurPeopleSlider /> : ""
                  }
                </>
              )}

            {module.__typename ===
              "GLSTRAPI_ComponentComponentsSelectGlobalModules" &&
              module.Select_Module === "Video_Client_Reviews_Slider" && (
                <>
                  {renderComponent || !isMobile ?
                    <TestimonialSlider
                      title={module.Title}
                      description={module.Description}
                      theme={module.Theme}
                    /> : ""
                  }
                </>

              )}
          </>
        )
      })}
      <Footer />
    </div >
  )
}

export default HomeComponents
