import React, {  useState, useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap"
import BannerSearch from "./BannerSearch"
import "./assets/styles/_index.scss"
import $ from "jquery"
import { isIOS } from "react-device-detect"
import { useMedia } from "react-use"
import { StaticImage } from "gatsby-plugin-image"

const HomeBannerSearch = (props) => {
  useEffect(() => {
    // backgroundAttachment: fixed - workaround for IOS
    if (isIOS) {
      $(window).scroll(function () {
        var scrolledY = $(window).scrollTop()
        $("#homewrapper").css("background-position", "left " + scrolledY + "px")
      })
    }
  })

  const isTablet = useMedia("(max-width: 992px)", false)
  const extraClass=props.extraClass ? props.extraClass : ""

  const [renderComponent, setRenderComponent] = useState(false);
  const [renderComponentBanner, setRenderComponentBanner] = useState(false);

  useEffect(() => {
    const handleMousemove = () => {
      if (!renderComponent) {
        setRenderComponent(true);
      }
    };

    const handleTouchmove = () => {
      if (!renderComponent) {
        setRenderComponent(true);
      }
    };

    const handleKeypress = () => {
      if (!renderComponent) {
        setRenderComponent(true);
      }
    };

    const handleTimeout = () => {
      if (!renderComponentBanner) {       
        setRenderComponentBanner(true);
      }
    };

    window.addEventListener("mousemove", handleMousemove);
    window.addEventListener("touchmove", handleTouchmove);
    window.addEventListener("keypress", handleKeypress);
    const timeout = setTimeout(handleTimeout, 500);

    return () => {
      window.removeEventListener("mousemove", handleMousemove);
      window.removeEventListener("touchmove", handleTouchmove);
      window.removeEventListener("keypress", handleKeypress);
      clearTimeout(timeout);
    };
  }, [renderComponent]);

  return (
    <section className="home-banner-search-wrapper " id={"homewrapper"} >
      {/* <img
        src={isTablet ? BannerOverlayTablet : BannerOverlay}
        className="img-fluid d-md-block d-none banner-overlay-img"
      /> */}
      {isTablet ? (
        <StaticImage
          src={"./assets/images/parallax-hero-bg-tablet.svg"}
          alt="full-width-banner-img"
          className="img-fluid d-md-block d-none banner-overlay-img"
        />
      ) : (
        <StaticImage
          src={"./assets/images/parallax-hero-bg.svg"}
          alt="full-width-banner-img"
          className="img-fluid d-md-block d-none banner-overlay-img"
        />
      )}
      {/* <img src={bgImg} className="img-fluid banner-img" /> */}
      {/* <img
        src={BannerImgMobile}
        className="d-md-none d-block banner-img-mobile"
      /> */}
      { renderComponentBanner &&
        <StaticImage
          src={"./assets/images/home-banner.jpg"}
          alt="full-width-banner-img"
          className={`d-md-none d-block banner-img-mobile ${extraClass}` }
        />
      }
      <div className="overlay-bg d-block d-md-none"></div>
      <div className="banner-search">
        <Container>
          <Row>
            <Col lg={12}>
              <h1>Find your perfect home</h1>
              <p>Or <a href='/property-valuation-in-dubai/'>fill out the form</a>, and we will be in touch within 3 hours</p>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <BannerSearch ptypetag="for-sale" homeBanner sales />
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  )
}

export default HomeBannerSearch
