import React from "react"
import { graphql } from "gatsby"
import "../scss/global.scss"
import HomeComponents from "./home-components"
import SEO from "../components/Seo/seo"

function HomeTemplate(props) {
  const GQLPage = props.data?.glstrapi?.page
  const GQLModules = GQLPage?.add_components
  const locationState = props.location.state
  return (
    <>
      <SEO
        title={GQLPage.seo_meta ? GQLPage.seo_meta.title : GQLPage.title}
        description={GQLPage.seo_meta ? GQLPage.seo_meta.description : ""}
      />     
      <HomeComponents
          GQLPage={GQLPage}
          GQLModules={GQLModules}
          locationState={locationState}
        />      
    </>
  )
}

export default HomeTemplate

export const pageQuery = graphql`
  query GetHomePage($id: ID!) {
    glstrapi {
      page(id: $id) {
        alias
        imagetransforms
        seo_meta {
          description
          id
          title
          keywords
          image {
            url
          }
        }
        banner_section {
          banner_content
          show_share
          banner_image {
            url
            alternativeText
            url_sharp {
              id
              childImageSharp {
                gatsbyImageData(
                  formats: WEBP
                  height: 1059
                  width: 1440
                  layout: FIXED
                  quality: 90
                  transformOptions: { cropFocus: CENTER, fit: COVER }
                )
              }
            }
          }
          button_cta_1 {
            theme
            target
            id
            label
            custom_link
            menu_item {
              slug
              label
              parent {
                slug
                label
                parent {
                  slug
                  label
                  parent {
                    slug
                    label
                  }
                }
              }
            }
          }
          button_cta_2 {
            theme
            target
            id
            label
            custom_link
            menu_item {
              slug
              label
              parent {
                slug
                label
                parent {
                  slug
                  label
                  parent {
                    slug
                    label
                  }
                }
              }
            }
          }
          content
          id
          show_reviews
          show_search
          title
          Icon_Stats {
            Icon
            Title
            description
          }
        }
        choose_form
        content
        id
        layout
        menu {
          slug
          label
          id
          parent {
            label
            slug
            parent {
              slug
              label
              parent {
                slug
                label
              }
            }
          }
        }
        page_class
        publish
        popular_searches {
          id
        }
        staff_contact {
          content
          designation
          email
          id
          image {
            url
          }
          imagetransforms
          mobile_no
          name
          publish
          slug
          tile_image {
            url
          }
          title
        }
        title

        add_components {
          ... on GLSTRAPI_ComponentComponentsSelectGlobalModules {
            __typename
            id
            Description
            Select_Module
            Theme
            Title
          }

          ... on GLSTRAPI_ComponentComponentsAreaGuideContent {
            __typename
            id
            heading
            description
            accordianContent {
              title
              id
              description
            }
          }

          ... on GLSTRAPI_ComponentComponentsIconGridItems {
            __typename
            id
            Heading
            Title
            Icon_Items {
              Icon
              Title
              content
              id
            }
          }

          ... on GLSTRAPI_ComponentBlocksRichTextBlock {
            __typename
            id
            content
          }

          ... on GLSTRAPI_ComponentModulesDetailPageModules {
            __typename
            id
            select_module
          }

          ... on GLSTRAPI_ComponentComponentsStaticContent {
            __typename
            id
            Content
          }

          ... on GLSTRAPI_ComponentComponentsFaq {
            __typename
            id
            theme
            title
            faq {
              description
              title
            }
          }
          ... on GLSTRAPI_ComponentComponentsShowFeaturedProperty {
            id
            __typename
            show
          }
          ... on GLSTRAPI_ComponentComponentsSelectFormsModule {
            __typename
            id
            select_form
          }
          ... on GLSTRAPI_ComponentComponentsTextBlockWithImage {
            __typename
            id
            layout_type
            Button_CTA {
              custom_link
              id
              label
              target
              theme
              menu_item {
                slug
                link_type
                label
                id
                parent {
                  slug
                  label
                  parent {
                    slug
                    label
                  }
                }
              }
            }
            media_block {
              id
              media_file {
                url
                alternativeText
                url_sharp {
                  id
                  childImageSharp {
                    gatsbyImageData(
                      formats: WEBP
                      height: 460
                      width: 560
                      layout: FIXED
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: COVER }
                    )
                  }
                }
              }
            }
            text_block {
              description
              title
            }
          }
        }
      }
    }
  }
`
